import React from "react";

function DownloadButtons() {
  return (
    <div className="download-buttons-container">
      <a href="#" onClick={(e) => e.preventDefault()}>
        <button
          style={{ backgroundColor: "grey", cursor: "not-allowed" }}
          disabled
        >
          Download for iOS
        </button>
      </a>
      {/* Active button for Android */}
      <a
        href="https://play.google.com/store/apps/details?id=com.jaysmart.piideo&pcampaignid=web_share"
        target="_blank"
        rel="noopener noreferrer"
      >
        <button>Download for Android</button>
      </a>
    </div>
  );
}

export default DownloadButtons;
